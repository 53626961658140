.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper{
  width: 100%;
  height: 100vh;
  /* background-color: black; */
  display: grid;
  grid-template-columns: auto auto;
}

/* .wrapper.active{
  grid-template-columns: 15% 85%;
} */

.left-section{
  height: 100%;
}

.left-section .side-nav{
  transition: 0.100s all ease-in-out;
}

.left-section .side-nav{
  height: 100%;
  width: 70px;
  flex: none;
  background-color: rgb(21, 21, 21);
  background-image: url('../src/assets/logo-nav-bg.svg');
  background-position: center center;
  /* background-repeat: no-repeat; */
  background-size: 50%;
  background-attachment: fixed;
  overflow: hidden;
}

.left-section .side-nav.active{
  width: 200px;
}

.left-section .side-nav .logo-container{
  display: flex;
  justify-content: center;
  margin: auto;
  border-bottom: 1px solid rgb(170, 170, 170);
  margin: 0 4px;
  padding: 10px 0;
}

.left-section .side-nav .logo-container img{
  /* width: 40px; */
  height: 40px;
}

.left-section .side-nav.active img{
  /* width: 60px; */
  height: 40px;
}
.left-section .side-nav.active .logo-container{
  margin: 0 8px;
  
}

.left-section .side-nav .navigation-section ul{
  width: 100%;
  padding:0 2px;
}

.left-section .side-nav.active .navigation-section ul{
  padding: 0;
}
.left-section .side-nav .navigation-section ul li.outer-nav-menu{
  width: 100%;
  
}

.left-section .side-nav .navigation-section ul li.outer-nav-menu .outer-nav-li{
  color: rgb(119, 118, 118);
  font-size: 10px;
  margin: 6px 3px;
}

.left-section .side-nav.active .navigation-section ul li.outer-nav-menu .outer-nav-li{
  font-size: 13px;
  margin: 6px 15px;
 
}

.left-section .side-nav .navigation-section ul li ul li.inner-nav-menu{
  width: 100%;
  height: 45px;
  border-bottom: 1px solid rgba(85, 85, 85, 0.231);
  
}

.left-section .side-nav.active .navigation-section ul li ul li.inner-nav-menu{
  display: flex;
  align-items: center;
  border: none;
  height: 45px;
  /* padding: 0 7px; */
  position: relative;
}

.left-section .side-nav .navigation-section ul li ul li.inner-nav-menu span.count{
 display: none;
 color: rgb(255, 255, 255);
 font-size: 12.5px;
 font-weight: 500;
 position: absolute;
 background-color: #d87529;
 padding: 0.25px 5.2px;
 border-radius: 5px;
 right: 10px;
}

.left-section .side-nav.active .navigation-section ul li ul li.inner-nav-menu span.count{
 display: block;
}

.nav-title{
  display: none;
}

.left-section .side-nav .navigation-section ul li .nav-li:hover .nav-title{
  display: block;
}
.left-section .side-nav.active .navigation-section ul li .nav-li .nav-title{
  display: block;
}


.left-section .side-nav .navigation-section ul li ul li.inner-nav-menu.active{
  background-color: rgb(54, 54, 54);
}

.react-icons {
  background-color: rgb(160, 159, 159);
}

.left-section .side-nav .navigation-section ul li .react-icons{
  background-color: transparent;
  color: white;
}

.left-section .side-nav .navigation-section ul li .nav-li{
  width: 100%;
  text-decoration: none;
  margin: 5px 0px;
  color: rgb(228, 227, 227);
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.left-section .side-nav.active .navigation-section ul li .nav-li{
 
  margin: 0 15px;
  font-size: 14.2px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.left-section .side-nav.active .navigation-section ul li .nav-li span{
  margin: 0 10px;
}

.right-section{
  width: 100%;
  height: 100%;
  padding: 0 30px;
  overflow-y: scroll;
  background-color: rgb(255, 255, 255);
}

.right-section::-webkit-scrollbar{
  width: 10px;
}
.right-section::-webkit-scrollbar-thumb{
  background-color: rgb(40, 40, 40);
  border-radius: 10px;
}

.right-section .top-nav{
  width: 100%;
  height: 70px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(224, 224, 224, 0.696);
  /* position: fixed; */
}
.right-section .top-nav .avator-section{
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-section .top-nav .avator-layout{
  position: relative;
  top: 8Px;
  margin: 0 12px;

}

.right-section .top-nav .avator-layout .status-box{
  width: 10px;
  height: 10px;
  background-color: #ffa500;
  border-radius: 50%;
  position: absolute;
  right: 10%;
}

.right-section .top-nav .avator-layout .avator{
  width: 40px;
  height: 40px;
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
  margin-bottom: 15px;
  cursor: pointer;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  
}
.right-section .top-nav .logout-btn{
  /* height: 30px; */
  padding: 7px 12px;
  outline: none;
  border: none;
  color: whitesmoke;
  background-color: black;
  border-radius: 3px;
  font-size: 11px;
  font-weight: bold;
}

.right-section .top-nav .toogle-icon{
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}
.right-section .top-nav .toogle-icon:hover{
  background-color: rgba(240, 240, 240, 0.374);
  border-radius: 6px;
}



.right-section .main-section{
  width: 100%;
  /* height: 100vh; */
  /* background-color: #282c34; */
}

.outer-wrapper{
  width: 100%;
  position: relative;
}

.profile-box{
  width: 240px;
  /* height: 300Px; */
  background-color: #282c34;
  position: absolute;
  right: 2%;
  top: 9%;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  display: none;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px 15px 34px 15px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.138);
  z-index: 1;
  
}

.profile-box.active{
  display: flex;
}

.profile-box .avator{
  width: 60px;
  height: 60px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  /* border: 1px solid #c8c8c8; */
  box-shadow: 0px 0px 5px 1px #00000016;
}


.profile-box .detail-box{
  padding: 0;
 margin: 15px 0;
  
}

.profile-box .detail-box p{
  text-align: center;
  margin: 0;
  /* font-size: 13px; */
  letter-spacing: 1px;
  color: #585858;
}

.profile-box .profile-btn{
  width: 100%;
  padding: 5px 0;
  border: none;
  outline: none;
  border: 1px solid rgb(130, 130, 130);
  background-color: white;
  color: rgb(74, 74, 74);
  font-size: 13px;
  /* font-weight: bold; */
  margin: 10px 0;
  border-radius: 16px;
}

.profile-box .profile-btn:hover{
  color: rgb(15, 15, 15);
}
/* ------------------------dashboard styling------------------------------ */

.memo-section{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-color: #282c34; */

}

.memo-section .memo-container{
  padding: 17px;
  background-color: rgb(248, 248, 248);
  margin: 10px;
  border-radius: 15px;
  border: 1px solid rgb(216, 216, 216);
}

.memo-section .memo-container .memo-title{
  color: rgb(67, 67, 67);
  font-weight: lighter;
}

.memo-section .memo-container .memo-title span{
  padding: 3px 5px;
  /* background-color: rgb(44, 44, 44); */
  border-radius: 5px;
  color: black;
  /* font-size: 12px; */
  font-weight: bold;
}

.memo-section .memo-container .memo-data{
  font-size: 24px;
  font-weight: bold;
}

.filter-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.filter-section .filter-box .search-box input{
  width: 200px;
  border: 1px solid rgb(112, 112, 112);
  padding: 8px 6px;
  border-radius: 10px;
  outline: none;
  font-size: 13px;
}

/* ------------------------------------------------------------------------ */

/*----------------------- auth styling----------------------------------------- */
.auth-wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-box{
  width: 400px;
  box-shadow: 1px 2px 15px 5px rgba(189, 187, 187, 0.427);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 16px 0px;
  border-radius: 16px;
}

.auth-box .logo{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-box .inputs{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
  font-size: 13px;
  letter-spacing: 1px;
}

.auth-box .inputs input{
  /* margin: 10px 0; */
  /* height: 40px; */
  outline: none;
  border: none;
  /* background-color: rgb(239, 239, 239); */
  border-radius: 7px;
  /* padding: 3.4px 16px; */
}

.copy-right{
  color: #545454;
}
/* -------------------------------------------------------------------------------- */

.popup-form{
  /* max-height: 700px; */
  min-width: 550px;
  padding: 0px 0px 25px 0px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.popup-form.invoice-model{
  /* height: 610px!important; */
  min-width: 900px;
  /* background-color: #282c34; */
}

.scrollable-section{
  overflow-y: scroll;
}

.scrollable-section{
  height: 520px;
}
.package-form-header{
  /* height: 30px; */
  width: 100%;
  background-color: rgb(239, 239, 239);
  padding: 15px 7px 0px 17px;
  font-weight: bolder;
  border-bottom: 1px solid rgb(214, 214, 214);
}


/* ///////////////////////////////////////////////////////////////////////////////// */

.loader{
  height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .logo{
  height: 70px;
  width: 70px;
}
.loader .logo svg path{
  animation: load 0.75s ease-in-out;
  animation-iteration-count: infinite;
}

.loader .logo svg path.cls-1{
  animation-delay: 0.1s;
}
.loader .logo svg path.cls-2{
  animation-delay: 0.1s;
}
.loader .logo svg path.cls-3{
  animation-delay: 0s;
}
.loader .logo svg path.cls-4{
  animation-delay: 0s;
}

@keyframes load {
  0%{
    fill: rgb(151, 151, 151);
  }
  50%{
    fill: rgb(99, 99, 99);
  }
  75%{
    fill: rgb(94, 94, 94);
  }
  100%{
    fill: rgb(151, 151, 151);
  }
}

.inquiery-paragraph{
  /* background-color: #f1f1f1; */
  padding: 10px;
  /* border-radius: 5px; */
  color: black;
  border-top: 1px solid rgb(217, 217, 217);
}

.errorList{
  min-width: 13rem;
  text-align: center;
  color: rgb(223, 80, 80);
}

.selection-option{
  height: 47px;
  color: black!important;
}

.web-update-form,
.user-registration-form{
  border-radius: 10px;
}

.navigation-section{
  height: 100%;
  /* background-color: white; */
}

.navigation-group{
  height: 72vh;
  /* background-color: white; */
  overflow-y: scroll;
  overflow-x: hidden;
}
.package-chip-area{
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}
.scrollable-section::-webkit-scrollbar,
.package-chip-area::-webkit-scrollbar,
.orders-list::-webkit-scrollbar,
.navigation-group::-webkit-scrollbar{
  width: 7px;
  /* background-color: #282c34; */
}
.scrollable-section::-webkit-scrollbar-thumb,
.package-chip-area::-webkit-scrollbar-thumb,
.orders-list::-webkit-scrollbar-thumb,
.navigation-group::-webkit-scrollbar-thumb{
  background-color: #5b5b5b;
  border-radius: 10px;
}

.orders-list{
  max-height: 55vh;
  width: 600px;
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
}
.orders-fields-list{
  min-width: 300px;
}

.order-item{
  height: 100px;
  width: 100%;
  background-color: #8d909728;
  border-radius: 10px;
  flex: none;
  overflow: hidden;
  padding: 0px 10px;
}

.order-item-property{
  display: flex;
  width: 25%;
  height: 100%;
  /* background-color: #ffffff; */
  flex-direction: column;
  justify-content: center;
}

.property-label{

}
.property-value{
  font-weight: bold;
}

.user-registration-image{
  height: 200px;
  width: 200px;
  background-color: #282c34;
  background-image: url(../../assets/man-user.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 10px;
  cursor: pointer;
}

.pform{
  width: 700px;
  max-width: 1000px!important;
}
.pform-container-1,
.pform-container-2{
  width: 50%;
}

.inquiry-text{
  max-width: 100px!important;
}

.profile-container{
  width: 100%;
  height: auto;
  margin: 16px 0px;
  padding: 25px 0px;
  background-color:#f9f9f9;
  border-radius: 10px;
}

.profile-container .avatar{
  height: 160px;
  width: 160px;
  background-color:#282c34;
  border-radius: 10px;
}

.hr{
  width: 100px;
  height: 1px;
  width: auto;
  background-color: #eaeaea;
}
/* ///////////////////////////////////////////////////////////////////////////////// */
/* ------------------------invoice------------------------- */

.invoice-container {
  display: flex;
}

.invoice-container .left {
  width: 70%;
  height: 100%;
  padding: 20px;
}

.invoice-container .left .counter-section {
  display: flex;
  justify-content: space-between;
}

.invoice-container .left .counter-section .counter p {
  font-size: 17px;
  font-weight: 400;
  color: rgb(104, 104, 104);
}

.invoice-container .left .counter-section .counter p span {
  font-weight: bold;
  font-size: 20px;
  margin: 2px 0;
}



.invoice-display {
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.invoice-display .header-section {
  width: 100%;
  height: 55px;
  /* border: 1px solid rgb(197, 197, 197); */
  border-radius: 10px;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.invoice-display .header-section .btn .download-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(24, 24, 24);
  color: whitesmoke;
  font-size: 14px;
  font-weight: 500;
}

.invoice-display .header-section .search {
  width: 230px;
  height: 37px;
  border: 1px solid rgb(212, 212, 212);
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 1px 7px;
}

.invoice-display .header-section .search input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
  font-size: 13px;
  /* margin: 18px 0; */
  background-color: transparent;
}

.invoice-display .invoice {
  /* width: 830px; */
  max-width: 1300px;
  height: auto;
  margin: 0 auto;
  border: 1px solid rgb(230, 230, 230);
  margin: 10px 0;
  border-radius: 7px;
  padding: 20px;
}

.invoice-display .invoice .invoice-header{
  display: flex;
  justify-content: space-between;
}

.invoice-display .invoice .invoice-header h3{
  font-size: 20px;
}

.invoice-display .invoice .invoice-header .company-details{
  width: auto;
}
.invoice-display .invoice .invoice-header .company-details p{
  /* font-size: 14px; */
  margin: 0;
}
.invoice-display .invoice .invoice-header .invoice-details{
  width: 250px;
}

.invoice-display .invoice .invoice-header .invoice-details p {
  margin: 0;
  /* font-size: 14px; */
}

.invoice-display .invoice .invoice-body{
  margin: 25px 0;
}

.invoice-display .invoice .invoice-body .bill-to-header{
  padding: 1px 0;
  width: 100%;
  background-color: rgb(240, 240, 240);
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  /* color: black; */
}

.invoice-display .invoice .invoice-body .bill-to-header p{
  color: rgb(24, 24, 24);
  /* font-size: 13px; */
  font-weight: bold;
  margin: 3px 2px;
}

.wp{
  width: 100px;
}

.invoice-display .invoice .invoice-body .bill-to-info p{
  margin: 0;
  /* font-size: 13px; */
}

.invoice-display .invoice .invoice-body .bill-to-info .ship-to{
  width: 200px;
  margin: 0 10px;
}

.invoice-display .invoice .invoice-header .invoice-details p span,
.invoice-display .invoice .invoice-body .bill-to-info p span{
  font-weight: bolder;
}

.invoice-display .invoice .invoice-body .invoice-discription table{
  width: 100%;
}

.invoice-display .invoice .invoice-body .invoice-discription table thead{
  background-color: rgb(29, 29, 29);
  color: whitesmoke;
  font-size: 14px;

}

@media screen and (max-width: 1020px) {
  .invoice-container {
    flex-direction: column;
  }
  .invoice-container .left{
    width: 100%;
  }
  .invoice-container .right{
    width: 100%;
  }
}

@media screen and (max-width: 655px) {
  .invoice-container .invoice {
    display: none;
  }
}

/* ///////////////////////////////////////////////////////////////////////////////// */