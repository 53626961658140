.calendar-container {
    top             : 0;
    left            : 0;
    height          : 100vh;
    width           : 100%;
    position        : fixed;
    background-color: rgba(0, 0, 0, 0.367);
    z-index         : 1000000 !important;
    display         : flex;
    align-items     : center;
    justify-content : center;
    overflow        : hidden;
}

.calendar-container .calendar {
    min-height        : 400px;
    max-height        : 550px;
    width             : 700px;
    background-color  : white;
    border-radius     : 10px;
    display           : flex;
    flex-direction    : column;
    /* justify-content: space-between; */
    overflow          : hidden;
}


.calendar-container .calendar .calendar-header {
    padding            : 10px 25px;
    /* border          : 1px solid black; */
    display            : flex;
    flex-direction     : row;
    justify-content    : space-between;
    align-items        : center;
    /* background-color: black; */
}

.calendar-container .calendar .calendar-header .calendar-header-btn-container button:nth-child(0),
.calendar-container .calendar .calendar-header .calendar-header-btn-container button:nth-child(2) {
    height          : 100%;
    padding         : 10px 16px;
    /* border       : 1px solid black; */
    /* margin       : 5px 0px; */
    border          : none;
    background-color: rgb(242, 242, 242);
    border-radius   : 10px;
    font-weight     : bold;
}

.calendar-container .calendar .calendar-body {
    height   : 100%;
    padding  : 5px 10px;
    /* border: 1px solid black; */
}

.calendar-bottom {
    /* height: 100px!important; */
    border-top: 1px solid grey;
    width     : 100%;
}

.calendar-section {
    display       : flex;
    flex-direction: column;
    padding       : 0px 10px;
}

.calendar-section .weeks {
    width              : 100%;
    padding            : 10px;
    margin             : 0px 0px;
    display            : flex;
    align-items        : flex-start;
    justify-content    : space-between;
    flex-direction     : row;
    /* background-color: black; */
}

.calendar-section .weeks .dates {
    height          : 40px;
    width           : 40px;
    border          : none;
    background-color: transparent;
    border-radius   : 10px;
    transition      : 0.25s all ease-in-out;
}

.calendar-section .weeks .dates.disabled {
    background-color: #f9f9f9;
    color           : #c0c0c0;
}

.calendar-section .weeks .dates.current {
    /* background-color: #4184b5; */
    /* color: #c0c0c0; */
    border         : 2.5px solid #4184b5;
    /* border-color: black; */
    /* border-width: 1px; */
    font-weight    : bolder;
}

.calendar-section .weeks .dates.selected {
    background-color: #000000 !important;
    color           : #ffffff !important;
    border          : 2.5px solid #ffffff;
    outline         : 2.5px solid #424242;
    outline-offset  : 1.5px;
    /* border-color : black; */
    /* border-width : 1px; */
    font-weight     : bolder;
    /* #4184b5 */
}

.calendar-section .weeks .dates.current-selected {
    background-color: #000000 !important;
    color           : #ffffff !important;
    border          : 2.5px solid #ffffff;
    outline         : 2.5px solid #ff9900; /*#4184b5;*/
    /* outline         : 2.5px solid #ff9900; */
    outline-offset  : 1.6px;
    /* border-color : black; */
    /* border-width : 1px; */
    font-weight     : bolder;
}

.calendar-section .weeks .weeks-days {
    height          : 40px;
    width           : 40px;
    border          : none;
    background-color: transparent;
    border-radius   : 10px;
    border-radius   : 10px;
}

.week-days-bg {
    height          : 40px;
    width           : 100%;
    left            : 0;
    border-radius   : 10px;
    background-color: #e6e6e66f;
}

.calendar-section .weeks .dates:hover {
    background-color: rgb(242, 242, 242);
}

.clock-container {
    height            : 310px;
    width             : 310px;
    background-color  : #fcfcfc;
    border-radius     : 50%;
    /* display        : flex; */
    /* align-items    : center; */
    /* justify-content: center; */
    position          : relative;
    border            : 2.5px solid rgb(209, 209, 209);
    flex              : none;
}

.clock-inner-circle {
    height       : 270px;
    width        : 270px;
    border       : 1px solid rgb(241, 241, 241);
    position     : absolute;
    top          : calc(50% - 135px);
    left         : calc(50% - 135px);
    border-radius: 50%;
}

.clock-center-circle {
    height          : 10px;
    width           : 10px;
    border          : 2.5px solid #4184b5;
    position        : absolute;
    top             : 50%;
    left            : 50%;
    border-radius   : 50%;
    background-color: #4184b5 !important;
}

.clock-numbers {
    height             : 310px;
    border-radius      : 50%;
    /* padding         : 34px; */
    /* background-color: blueviolet; */
    transform          : rotate(-5deg);
}

.clock-button {
    min-height         : 100% !important;
    position           : absolute;
    top                : 0px;
    /* bottom          : 0; */
    padding            : 10px 0px 0px 0px;
    left               : 45%;
    font-weight        : bold;
    /* background-color: violet; */
}

.clock-button .btn {
    height             : 34px;
    width              : 34px;
    padding            : 5px;
    /* background-color: #4185b5; */
    background-color   : #fcfcfc;
    border-radius      : 50%;
    /* color           : white; */
    color              : black;
    font-weight        : bolder;
}

.clock-coverage .btn {
    height          : 34px;
    width           : 34px;
    padding         : 5px;
    background-color: #b0bae7;
    border-radius   : 50%;
}

.isScheduledTime {
    background-color: #4184b5 !important;
    color           : rgb(255, 255, 255) !important;
}

.isDisabledTime {
    background-color: #fcfcfc !important;
    color           : rgb(211, 211, 211) !important;
}
